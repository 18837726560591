
<script setup>
    import { computed } from "vue";
    import colors from "./colors.ts";
    import { Url } from 'o365-utils';

    const props = defineProps({
        "title": { type: String },
        "orgUnit": { type: String },
        "process": { type: String },
        "responsible": { type: String },
        "severityColor": { type: String },
        "currentStep": { type: String },
        "checkedOutByName": { type: String },
    });
</script>

<template>
    <MCard :onClick="true" v-bind="$attrs">
        <div style="word-break: break-all;">
            <template v-if="title">
                <div class="fw-medium text-primary">
                    {{ title }}
                </div>
            </template>

            <template v-if="orgUnit">
                <div class="text-primary">
                    {{ orgUnit }}
                </div>
            </template>

            <template v-if="process">
                <div class="text-primary">
                    {{ process }}
                </div>
            </template>

            <template v-if="responsible">
                <div class="text-primary">
                    {{ responsible }}
                </div>
            </template>

            <div class="text-truncate text-muted" v-if="currentStep || severityColor">
                <i class="bi bi-circle-fill small" v-if="severityColor" :style="{ color: `#${severityColor}` }"></i> <span v-if="currentStep">{{ currentStep }}</span>
            </div>
            
            <!--todo:-->
            <template v-if="checkedOutByName">
                <div class="d-flex align-items-center gap-2 border-top mt-2 pt-2">
                    <i class="bi bi-lock-fill text-danger me-1"></i>
                    <span class="text-danger">
                        {{ $t('Checked out by') }}
                        {{ checkedOutByName }}
                    </span>
                </div>
            </template>

            <!-- Default slot for custom: --> 
            <slot></slot>
        </div>
    </MCard>
</template>

<style scoped>
    .afm-app-card {
        display: flex;
        position: relative;

        color: rgb(15%, 15%, 15%);
        background-color: var(--bs-white);

        text-decoration: none;

        border-radius: 0.5rem;
        box-shadow: 2px 4px 8px rgba(0%, 0%, 0%, 5%);
        border: 1px solid var(--bs-gray-300);

        /* padding: 0.75rem; */

        transition: all 200ms ease-in-out;

        &:active {
            background-color: var(--bs-gray-200);
            transition: all 0ms;
        }
    }


    .afm-app-card:before {
        position: absolute;
        inset: 0;
        background-color: rgb(50%, 50%, 50%);
        opacity: 0%;
        transition: all 200ms ease-in-out;
    }
    .afm-app-card:hover:before {
        opacity: 100%;
    }
</style>
